<template>
  <b-row>
    <div class="container ikincikisim">
      <b-row>
        <b-col md="12">
          <b-card class="aboutCard">
            <b-card-text>
              <h2 class="display-4 aboutTitle mb-2">
                {{ KvkkBasvuruData[0].title }}
              </h2>
              <hr />
              <div v-html="KvkkBasvuruData[0].text"></div>
            </b-card-text>
            <b-card-text>
              <a href="./docx/KVKK-Basvuru-Formu.docx" download="KVKK-Basvuru-Formu.docx" class="formLink">Başvuru Formu İçin Tıklayınız</a>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </b-row>
</template>
<script>
import { BImg, BRow, BCol, BCard, BCardText, BLink } from "bootstrap-vue";

export default {
  components: {
    BImg,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink
  },
  props: {
    KvkkBasvuruData: Array,
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;500&display=swap"); /* Navbar için stil */
.aboutTitle {
  font-family: "Roboto", sans-serif;
  color: white;
  text-align: center;
}
.formLink{
  color: white;
  border-bottom: 1px solid white;
}
.formLink:hover{
  color: rgba(255, 255, 255, 0.61);
  border-bottom: 1px solid rgba(255, 255, 255, 0.61);

}
.ikincikisim {
  text-align: left;
  font-family: "Prompt", sans-serif;
  font-size: 16px;
}
.aboutCard {
  background-color: transparent !important;
  border: none !important;
  color: white;
}
@media (max-width: 767px) {
  .aboutTitle {
    font-size: 35px;
  }
}
</style>
