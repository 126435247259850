<template>
  <div>
    <!-- İmg-->
    <b-img
      class="aboutImg mt-0"
      :src="imgDataUrl"
      fluid
      alt="Responsive image"
    />
    <b-tabs align="center" pills class="nav-pill-secondary mt-1">
      <b-tab class="tabButton" :title="getTabTitle('KVKK')">
        <b-card-text>
          <br /><br />
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="10"
              ><detail-vue
                v-if="groupedData && allDatas"
                :kvkkData="groupedData[33]"
              >
              </detail-vue
            ></b-col>
            <b-col md="1"></b-col>
          </b-row>
        </b-card-text>
      </b-tab>

      <b-tab class="tabButton" :title="getTabTitle('KVKK BAŞVURU FORMU')">
        <b-card-text>
          <br /><br />
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="10">
              <kvkk-basvuru
                v-if="groupedData && allDatas"
                :KvkkBasvuruData="groupedData[34]"
              ></kvkk-basvuru
            ></b-col>
            <b-col md="1"></b-col
          ></b-row>
        </b-card-text>
      </b-tab>
      <b-tab class="tabButton" :title="getTabTitle('AYDINLATMA METNİ')">
        <b-card-text>
          <br /><br />
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="10">
              <illumination-vue
                v-if="groupedData && allDatas"
                :illuminationData="groupedData[35]"
              ></illumination-vue
            ></b-col>
            <b-col md="1"></b-col
          ></b-row>
        </b-card-text>
      </b-tab>
    </b-tabs>
    <footer-vue :AdressData="AdressData"> </footer-vue>
  </div>
</template>

<script>
import { BTabs, BTab, BRow, BCol, BCardText, BImg } from "bootstrap-vue";
import DetailVue from "./Detail.vue";
import kvkkBasvuru from "./kvkkBasvuru.vue";
import illuminationVue from "./illumination.vue";
import axios from "axios";
import footerVue from "../home/components/footer.vue";

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCardText,
    BCol,
    kvkkBasvuru,
    DetailVue,
    illuminationVue,
    footerVue,
    BImg,
  },
  data() {
    return {
      allDatas: undefined,
      groupedData: undefined,
      AdressData: [],
      imgDataUrl: "",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    // get method
    getData() {
      // console.log("GET DATA");
      this.groupedData = [];
      const languageCode = localStorage.getItem("language") === "2" ? 2 : 1;
      axios
        .get(
          `/api/Contents/GetListByPageIdAndLanguage?id=11&language=${languageCode}`
        )
        .then((result) => {
          this.getCommunications();
          if (result.data.success) {
            // console.log("BURADA LOGA BAK");
            //    console.log(result.data.data);
            this.allDatas = result.data.data;
            this.splitData(result.data.data);
            //   console.log(this.allDatas[0]);
          } else {
            console.error("API yanıtında başarısızlık:", result.data.message);
          }
        })
        .catch((error) => {
          console.error("Catch'e düştü", error);
        });
    },
    splitData(myData) {
      myData.forEach((item) => {
        //  console.log("fdgdfgd : ",item)
        const componentId = item.componentId;
        if (!this.groupedData[componentId]) {
          this.groupedData[componentId] = [];
        }
        this.groupedData[componentId].push(item);
      });
      this.imgDataUrl = this.groupedData[32][0].image;
      // console.log("gropudata", this.groupedData);
    },
    getCommunications() {
      const languageCode = localStorage.getItem("language") === "2" ? 2 : 1;
      axios
        .get(`/api/Informations/GetListByLanguage?language=${languageCode}`)
        .then((result) => {
          if (result.data.success) {
            this.AdressData = result.data.data;
            //   console.log(this.AdressData)
          } else {
            console.error("API yanıtında başarısızlık:", response.data.message);
          }
        })
        .catch((error) => {
          console.error("Catch'e düştü", error);
        });
    },

    getTabTitle(tabKey) {
      return localStorage.getItem("language") === "2"
        ? this.getEnglishText(tabKey)
        : this.getDefaultText(tabKey);
    },
    getEnglishText(tabKey) {
      switch (tabKey) {
        case "KVKK":
          return "KVKK";
        case "KVKK BAŞVURU FORMU":
          return "KVKK APPLICATION FORM";
        case "AYDINLATMA METNİ":
          return "CLARIFICATION TEXT";
        default:
          return tabKey; // If no translation is available, return the original tab key
      }
    },
    getDefaultText(tabKey) {
      return tabKey; // Use the tab key as the default text
    },
  },
};
</script>
<style scoped>
.tabButton {
  font-family: "Prompt", sans-serif;
  border: 1px solid;
  background-color: #0725419a;
}
.aboutImg {
  width: 100%;
  height: 500px;
  object-fit: cover;
  object-position: 50% 50%;
}
.nav-pill-secondary {
  margin-left: 40px;
  margin-top: 0px;
}
@media (max-width: 1500px) {
  .nav-pill-secondary {
    margin-left: 0px;
    margin-top: 20px;
  }
  .aboutImg {
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: 50% 50%;
  }
}
</style>
